var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("header", [
      _c("div", { staticClass: "title" }, [
        _vm._v(" 活动监控 - " + _vm._s(_vm.$route.query.emailId) + " ")
      ])
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        !_vm.success
          ? [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.cloesEmail } },
                [_vm._v("点击关闭邮件发送")]
              )
            ]
          : [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.sec) + "秒后返回首页,点击立即返回")]
              )
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }