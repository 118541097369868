<template>
	<div class="container">
		<header>
			<div class="title">
				活动监控 - {{ $route.query.emailId }}
			</div>
		</header>
		<div class="content">
			<template v-if="!success">
				<el-button type="danger" @click="cloesEmail">点击关闭邮件发送</el-button>
			</template>
			<template v-else>
				<el-button type="primary" @click="$router.push('/')">{{sec}}秒后返回首页,点击立即返回</el-button>
			</template>
		</div>
	</div>
</template>

<script>
import { getLocal, setLocal } from '@/lib/local'
import {
	closeMailSend
} from '../../api/market.js'
export default{
	data(){
		return {
			isLogin: false,
			success: false,
			sec: 3
		}
	},
	created() {
		this.isLogin = !!getLocal()
		if(!this.isLogin){ // 若未登录，需先登录
			this.$Login()
		}
	},
	mounted() {
		
	},
	methods: {
		cloesEmail(){ // 关闭邮件发送
			if(!this.isLogin){
				this.$Login()
				return
			}
			this.$confirm('您确定要取消邮件发送吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				closeMailSend({
					emailId:this.$route.query.emailId
				}).then(res=>{
					if(res.code == 200){
						this.$message({
							type: res.data ? 'success' : 'error',
							message: res.data ? '关闭成功' :'关闭失败'
						})
						this.success = res.data
						if(res.data){
							let timer = setInterval(()=>{
								this.sec--
								if(this.sec == 0){
									clearInterval(timer)
									timer = null
									this.$router.push('/')
								}
							},1000)
						}
					} else {
						this.$message({
							type: 'error',
							message: res.message
						})
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '您在想想！'
				});          
			});
		}
	}
}
</script>

<style lang="scss" scoped>
	.container{
		height: 100%;
		header{
			line-height: 70px;
			font-size: 24px;
			border-bottom: 2px solid #ccc;
			.title{
				max-width: 1200px;
				margin: auto;
			}
		}
		.content{
			height: 100%;
			max-width: 1200px;
			margin: auto;
			display: flex;
			justify-content: center;
			align-items: center;
			
		}
	}
</style>